import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { BENCHMARK_DISPLAY_TYPE, VALUE_ADDED_DISPLAY_TYPE, exportGridData, getPerformanceGridProcessCellCallback, useGetPortfolioSettingsByIdQuery, useGetStatsPerformanceQuery } from '@/shared/api/services/portfolioService'
import { Card, GeneralError, Section } from '@/shared/components'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import SkeletonClientPortfolioPerformance from '../SkeletonClientPortfolioPerformance'
import PerformanceGrid, { PerformanceGridInitializeEvent } from '../grid/PerformanceGrid'
import PanelOptions from './PanelOptions/PanelOptions'
import { GridApi } from 'ag-grid-community'

import './ClientPortfolioPerformanceMobile.scss'

export interface ClientPortfolioPerformanceMobileProps {
  portfolioId: string, 
  asOfDate: string,
  sleeveId?: string
}

export default function ClientPortfolioPerformanceMobile({ 
  portfolioId, 
  asOfDate,
  sleeveId,
}: ClientPortfolioPerformanceMobileProps) {
  const [ isBenchmarkValueAddedSelected, setBencharmkValueAddedSelected ] = useState<boolean>(true)
  const [ isSleevesExpanded, setSleevesExpanded ] = useState<boolean>(true)
  const [ isFullScreen, setIsFullScreen ] = useState<boolean>(false)
  const { data, isFetching, isError, refetch } = useGetStatsPerformanceQuery({ portfolioId, asOfDate })
  const { data: portfolioSettings } = useGetPortfolioSettingsByIdQuery(portfolioId)
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, 'MMM dd, yyyy')}`
  const [ gridApi, setGridApi ] = useState(null as GridApi)
  
  if (isError) {
    return (
      <Card className='card-center card-ag-grid-wrapper card-ag-grid-performance' ariaLabel='Welcome'>
        <Section className='c-portfolio-section-chart' title='Performance' subtitle={subtitle}>
          <GeneralError title='Failed to load Portfolio Performance' onClick={refetch} />
        </Section>
      </Card>
    )
  }

  const filteredRows = (data || []).filter(p => {
    if (isBenchmarkValueAddedSelected) {
      return true
    }
    return ![BENCHMARK_DISPLAY_TYPE, VALUE_ADDED_DISPLAY_TYPE].includes(p.DisplayPLIType)
  }).map(p => ({ ...p }))

  const exportIcon = `${ isFullScreen ? 'down-left-and-up-right-to-center' : 'up-right-and-down-left-from-center' }`
  
  const handleFullScreenMode = () => {
    setIsFullScreen(!isFullScreen)
  }

  const handleGridInitialize = (event: PerformanceGridInitializeEvent) => {
    setGridApi(event.gridApi)
  }

  const handleDownloadClick = () => {
    exportGridData({
      gridApi: gridApi,
      excelTabName: 'Performance',
      fileName: `performance-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
      processCellCallback: getPerformanceGridProcessCellCallback(),
    })
  }
 
  return (
    <Card className={`card-center card-ag-grid-wrapper card-ag-grid-performance ${isFullScreen ? 'full-screen-mode' : ''}`} ariaLabel='Welcome'>
      {
        isFetching ? <SkeletonClientPortfolioPerformance /> :
        <Section className='c-portfolio-section-chart' title='' subtitle={subtitle} actions={[
          <DefaultButton 
            key={0}
            className='fullSizeButton'
            onClick={handleFullScreenMode}
          >
            <FontAwesomeIcon className='icons-mb' icon={['fas', `${ isFullScreen ? 'down-left-and-up-right-to-center' : 'up-right-and-down-left-from-center' }`]} />
          </DefaultButton>,
          <PanelOptions 
            key={1}
            isBenchmarkValueAddedFiltered={isBenchmarkValueAddedSelected}
            onChangeBenchValueAddedFilter={(value) => {
              setBencharmkValueAddedSelected(value)
            }}
            isExpanded={isSleevesExpanded}
            onChangeExpand={(value) => {
              setSleevesExpanded(value)
            }}
          ></PanelOptions>,
          <div  key='downloadBtn' className='download-button-container'>
            <DefaultButton className='download-button'  onClick={handleDownloadClick}>
              <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
            </DefaultButton>
          </div>
        ]}>
          { !isFetching ? <PerformanceGrid portfolioSettings={portfolioSettings} isFullScreenMode={isFullScreen} rows={filteredRows} isSleevesExpanded={isSleevesExpanded} onGridInitialize={handleGridInitialize}/> : null }
        </Section>
      }
    </Card>
  )
}
