import DonutChart from '@/lib/common/components/charts/donut-chart/DonutChart'
import { Card, Section } from '@/shared/components'
import { IDataGridCellStyleParams, PercentageColumn, TextColumn } from '@/lib/common/components/grid/Columns'
import DataGrid from '@/lib/common/components/grid/Grid'
import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import UtilService from '@/lib/common/services/util/UtilService'
import { useGetDashboardSettings } from '@/shared/api/services/legacyService'
import { IPortfolioSettings, SLEEVE_DISPLAY_TYPE, getGridExcelStyles, useGetStatsAllocationQuery } from '@/shared/api/services/portfolioService'
import { useState } from 'react'
import SkeletonClientPortfolioAllocation from '../../Skeletons/SkeletonClientPortfolioAllocation'
import AllocationDetail from './AllocationDetail/AllocationDetail'

import ReturnToTotalPortfolioButton from '../../TotalPortfolioButton/ReturnToTotalPortfolio'

import './AllocationMobile.scss'

export interface AllocationMobileProps {
  portfolioId: string,
  asOfDate: string,
  sleeveId?: string,
  portfolioSettings: IPortfolioSettings,
  setShowTotalPortfolioButton?: (showTotalPortfolioButton: boolean) => void,
  setTotalPortfolioId?: (totalPortfolioId: string) => void,
}

export default function AllocationMobile({
  portfolioId,
  asOfDate,
  sleeveId,
  portfolioSettings,
  setShowTotalPortfolioButton,
  setTotalPortfolioId,
}: AllocationMobileProps) {
  const [isDetailed, setIsDetailed] = useState<boolean>(false)
  const [indexSelected, setIndexSelected] = useState<number>(0)
  const { data: dashboardSettings } = useGetDashboardSettings()
  const { data, isFetching } = useGetStatsAllocationQuery({ portfolioId, asOfDate })
  let allocationTemplateGrid = []
  let allocationTemplateChart = []
  const parsedSleeveId = !!sleeveId ? Number(sleeveId) : 0
  const result = (data || [])

  let totalAssets;
  const firstOption = result.find(p => {
    if (p.Lvl === 0) {
      totalAssets = p
    }
    
    if (parsedSleeveId) {
      return (p.PLISk === parsedSleeveId) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
    }


    return (p.Lvl === 0) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
  })
  const options = [
    firstOption,
    ...result.filter(p => {
      return (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE) && (p.ParentPLISk === firstOption?.PLISk)
    })
  ].filter(option => !!option)

  if (!isFetching) {
    allocationTemplateGrid = options.map((row, i) => {
      return {
        name: row.Name,
        value: row.NetCommitCap,
        total: row.NetCommitCap,
        color: row.Color,
        currentMktValue: row.NetCommitCap,
        actualAllocation: row.TgtAllocLT,
        longTrmTrgt: row.TgtVarAmt,
        longTrmTrgtVariance: row.TgtVarAmtST,
        currencySymbol: row.CurrencySymbol,
        level: row.Lvl
      }
    })

    allocationTemplateChart = allocationTemplateGrid.filter(row => {
      return row.level === 1
    })
  }

  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, 'MMM dd, yyyy')}`
  
  const getInvestmentCellStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any

    styles['boxShadow'] = `5px 0px ${data.color} inset`
    styles['boxSizing'] = 'border-box !important'

    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }
    
    return styles
  }
  const getRowColor = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any
    
    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }
    
    return styles
  }

  const getRowStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any

    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }
    
    if(data?.name === 'Total Assets') {
      styles['font-weight'] = '600'
    }
    
    return styles
  }

  const getCellClass = (params: IDataGridCellStyleParams) => {
    if (params.rowIndex !== 0) {
      return 'c-allocation-tab'
    }
    
    return ''
  }

  const onClick = (event) => {
    if (event.rowIndex > 0) {
      setIndexSelected(event.rowIndex - 1)
      setIsDetailed(true)
    }
  }

  if (isDetailed) {
    return (
      <AllocationDetail indexSelected={indexSelected} asOfDate={subtitle} allocationData={allocationTemplateChart} onClose={(e) => setIsDetailed(e)}></AllocationDetail>
    )
  }

  const getAggregate = (params) => {

    const row = params
    const { name, statusAggregate } = row || {};
    let disName='';

    if(statusAggregate) {
      if (statusAggregate === 'Preliminary') {
        disName = ' * '
      } else if(statusAggregate === 'PROXY') {
        disName = ' + '
      }
    }
    return name + disName
  }

  const showTotalAssetsButton = () => {
    const { id } = totalAssets || {} as any

    setShowTotalPortfolioButton(false)

    if (sleeveId && totalAssets?.id != sleeveId) {
      setShowTotalPortfolioButton(true)
      setTotalPortfolioId(id)

      return (
        <div key={0}>
          <ReturnToTotalPortfolioButton portfolioId={portfolioId} asOfDate={asOfDate} id={id} />
        </div>
      )
    }

    return null
  }

  return (
    <>
      {dashboardSettings?.enablePortfolioDashboardAllocation ? (
        <Card className='c-onboarding-welcome__right__card card-left-min card-mobile-right' ariaLabel='Welcome'>
          <Section className='c-portfolio-section' title='Allocation' subtitle={subtitle} actions={[
            showTotalAssetsButton()
          ]}>
            {
              isFetching ? <SkeletonClientPortfolioAllocation /> : (
                <div className='c-portfolio-section-allocation-mobile'>
                  <DonutChart
                    series={allocationTemplateChart}
                    legend={{
                      position: 'right'
                    }}
                    responsive={true}
                    centerTextOptions={{
                      showCenterText: true,
                      getCenterText: () => {
                        const maxValue = Math.max(...allocationTemplateChart.map(la => la.value))
                        return ({
                          top: '105px',
                          text: `$${UtilService.nFormatter(maxValue, 1)}`,
                          font: {
                            size: '30px',
                            family: 'Segoe UI'
                          }
                        })
                      }

                    }}
                    onSeriesClick={(seriesItem) => seriesItem}
                  />
                  <DataGrid
                    className='c-portfolio-allocation-mobile'
                    showToolBar={false}
                    rows={allocationTemplateGrid}
                    enablePagination={false}
                    domLayout='normal'
                    excelStyles={getGridExcelStyles({
                      currencySymbol: portfolioSettings?.currency?.symbol,
                      dateFormat: portfolioSettings?.dateFormat,
                    })}
                  >
                    <TextColumn onCellClicked={onClick} disableFilterColumn={true} title='' field='name' checkboxSelection={false} cellClass={getCellClass} cellStyle={getInvestmentCellStyle} valueGetter={getAggregate} />
                    <PercentageColumn title='Actual Alloc (%)' disableFilterColumn={true} field='actualAllocation' cellStyle={getRowStyle} width={150} />
                    <TextColumn disableFilterColumn={true} title='' field=' ' headerName='allocation-mobile' width={5} checkboxSelection={false} cellStyle={getRowColor} valueGetter={() => ''}/>
                  </DataGrid>
                </div>
              )
            }
          </Section>
        </Card>
      ) : null}
    </>
  )
}