import React from 'react'
import { Card, Section } from '@/shared/components'

import './ClientPortfolioDisclosures.scss'

interface DisclosuresProps {
  portfolioId: string
  asOfDate: string
  sleeveId?: string
}

const Disclosures: React.FC<DisclosuresProps> = ({ portfolioId, asOfDate, sleeveId }) => {
  return (
    <Card ariaLabel='Welcome'>
      <Section className="c-disclosure-description" title='Disclosures'>
        <p>Various Disclosures here</p>
      </Section>
    </Card>
  )
}

export default Disclosures
