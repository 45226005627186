export const MAIN_INVESTMENT_DASHBOARD = 'MAIN_INVESTMENT_DASHBOARD'
export const CLIENT_ONBOARDING_DASHBOARD = 'CLIENT_ONBOARDING_DASHBOARD'

export interface DashboardView {
  selectedDashboard: string
}

export interface DashboardSettings {
  enableMainInvestmentDashboard: boolean
  enableClientOnboardingDashboard: boolean
  enableClientDashboardPortfolioList: boolean
  enableClientDashboardRecentTrades: boolean
  enableClientDashboardMyCambridgeAssociatesTeam: boolean
  enableClientDashboardRecentDocuments: boolean
  enableClientDashboardFeaturedMedia: boolean
  enableClientDashboardInsightList: boolean
  enableClientDashboardEventList: boolean
  enablePortfolioDashboardMarketValue: boolean
  enablePortfolioDashboardAllocation: boolean
  enablePortfolioDashboardPerformance: boolean
  enablePortfolioDashboardRecentTrades: boolean
  enablePortfolioDashboardTopHoldings: boolean
  enablePortfolioDashboardPerformanceTab: boolean
  enablePortfolioDashboardTradesTab: boolean
  enablePortfolioDashboardDisclosuresTab: boolean
}

export interface ClientSettingsModel {
  accountId: string
  enableClientContactDocumentTab: boolean
  enableEmployeeDocumentTab: boolean
  enableMainInvestmentDashboard: boolean
  enableClientOnboardingDashboard: boolean
  enableClientDashboardPortfolioList: boolean
  enableClientDashboardRecentTrades: boolean
  enableClientDashboardMyCambridgeAssociatesTeam: boolean
  enableClientDashboardRecentDocuments: boolean
  enableClientDashboardFeaturedMedia: boolean
  enableClientDashboardInsightList: boolean
  enableClientDashboardEventList: boolean
  enablePortfolioDashboardMarketValue: boolean
  enablePortfolioDashboardAllocation: boolean
  enablePortfolioDashboardPerformance: boolean
  enablePortfolioDashboardRecentTrades: boolean
  enablePortfolioDashboardTopHoldings: boolean
  enablePortfolioDashboardPerformanceTab: boolean
  enablePortfolioDashboardTradesTab: boolean
  enablePortfolioDashboardDisclosuresTab: boolean
}

export const getDefaultClientSettings = (accountId: string) => {
  return {
    accountId,
    enableClientContactDocumentTab: true,
    enableEmployeeDocumentTab: true,
    enableMainInvestmentDashboard: true,
    enableClientOnboardingDashboard: false,
    enableClientDashboardPortfolioList: true,
    enableClientDashboardRecentTrades: false,
    enableClientDashboardMyCambridgeAssociatesTeam: true,
    enableClientDashboardRecentDocuments: true,
    enableClientDashboardFeaturedMedia: true,
    enableClientDashboardInsightList: true,
    enableClientDashboardEventList: true,
    enablePortfolioDashboardMarketValue: true,
    enablePortfolioDashboardAllocation: true,
    enablePortfolioDashboardPerformance: true,
    enablePortfolioDashboardRecentTrades: false,
    enablePortfolioDashboardTopHoldings: true,
    enablePortfolioDashboardPerformanceTab: true,
    enablePortfolioDashboardTradesTab: false,
    enablePortfolioDashboardDisclosuresTab: true,
  } as ClientSettingsModel
}
