import { DEFAULT_COLUMNDEFS, IDataGridColumnProps, PLACEHOLDER } from "@/lib/common/components/grid/Columns";
import { ColDef, ICellRendererParams } from 'ag-grid-community';

// component styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './ChangeMktValueColumn.scss';

export const CHANGE_MKT_VALUE_COLUMN_CELL_CLASS = "ChangeMktValueCell";

export const ChangeMktValueCellrenderer = (props: ICellRendererParams) => {
    const { data, value } = props
    const roundToOneDecimal = (value: number): string | number => {
      const str = value.toString();
      const decimalIndex = str.indexOf('.');
      
      if (decimalIndex === -1) {
          // If there is no decimal point, just return the number as is
          return str;
      }
      
      // Extract the part of the string up to the desired decimal places
      const result = str.substring(0, decimalIndex + 2);
      return result;
    }  

    if(value === null || value === undefined) {
      return (
        <div className='ChangeMktValueColumn'> --- </div>
      )
    }
    return (
      <div className='ChangeMktValueColumn'>
        <div className={ `ChangeMktValue ${value > 0 ? 'Positive' : 'Negative'}`  }>
          <FontAwesomeIcon icon={["fas", `${value > 0 ? 'arrow-up' : 'arrow-down'}`]} /> {value > 0 ? roundToOneDecimal(value) : roundToOneDecimal(value).toString().replace('-', '')}%
        </div>
      </div>
    );
};

export function ChangeMktValueColumn(props: IDataGridColumnProps) {
  return PLACEHOLDER;
}
ChangeMktValueColumn.columnDefs = <TRow extends any>(
  props: IDataGridColumnProps
): ColDef =>
  ({
    ...DEFAULT_COLUMNDEFS,
    autoHeight: true,
    filter: !props.disableFilterColumn ? "agTextColumnFilter" : false,
    floatingFilter: !props.disableFilterColumn,
    cellRendererFramework: ChangeMktValueCellrenderer,
    cellClass: CHANGE_MKT_VALUE_COLUMN_CELL_CLASS,
    disableWidth: false,
    headerClass: 'text-right ' + props.headerClass
  } as ColDef);