import { IListItem } from '../List'
import { Link, Icon } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import FileIcon from 'box-ui-elements/es/icons/file-icon'

interface Props {
  item: IListItem
  useBoxIcon?: boolean
}

const dtfOptions: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' }

export default function ListItem({ item, useBoxIcon = false }: Props) {
  const { i18n } = useTranslation()

  const datetime: string = item.date instanceof Date 
    ? new Intl.DateTimeFormat([i18n.language, ...navigator.languages], dtfOptions).format(item.date)
    : item.date

  return (
    <li className="c-list__item">
      <time className="c-list__item-dt" dateTime={datetime}>{datetime}</time>
      <Link className="c-list__item-title" href={item.href}>{item.title}</Link>
      <Link 
        className="c-list__item-action" 
        href={item.actionHref}
        onClick={item.onActionClick}
        underline={false}
      >
        {item.actionIconName ? (
          <>
            {useBoxIcon ? (
              <FileIcon title={`${item.actionIconName} file icon`} className="c-list__item-action-icon" extension={item.actionIconName} /> 
            ) : (
              <Icon className="c-list__item-action-icon" iconName={item.actionIconName} />
            )}
          </>
        ) : null} 
        <span className="c-list__item-action-text">{item.actionTitle}</span>
      </Link>
    </li>
  )
}
