import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TextCellRenderer = (props: ICellRendererParams) => {
  const { value } = props
  const formattedValue = value || ''
  if(props.colDef.cellRendererParams.headerName === 'allocation-mobile') {
    return  <FontAwesomeIcon style={{ color: '#AAAAAA' }} icon='chevron-right'/>
  }

  return (
    <div
      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      className={formattedValue.replace(' ', '')}
      //title={formattedValue}
    >
      {formattedValue}
    </div>
  )
}
