import { Card, GeneralError, Section } from '@/shared/components'
import { exportGridData, useGetPortfolioSettingsByIdQuery, useGetStatsTopFiveQuery } from '@/shared/api/services/portfolioService'
import TopFiveHoldingGrid from './TopFiveHoldingGrid/grid/TopFiveHoldingGrid'
import SkeletonClientPortfolio from '../Skeletons/SkeletonClientPortfolio'
import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridApi } from 'ag-grid-community'
import { useState } from 'react'
import useIsMobile from '@/shared/hooks/useIsMobile'

import './TopFiveHolding.scss'

export interface TopFiveHoldingProps {
  portfolioId: string, 
  asOfDate: string 
}

export default function TopFiveHolding({ 
  portfolioId, 
  asOfDate,
}: TopFiveHoldingProps) {
  const { data, isFetching, isError, refetch } = useGetStatsTopFiveQuery({ portfolioId, asOfDate })
  const { data: portfolioSettings } = useGetPortfolioSettingsByIdQuery(portfolioId)
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, portfolioSettings?.headerDateFormat || 'MMM dd, yyyy')}`
  const isMobile= useIsMobile('xs')
  const [ gridApi, setGridApi ] = useState(null as GridApi)
  
  if (isError) {
    return (
      <Card className='c-onboarding-welcome__right__card card-right TopFiveHolding c-top-five-card' ariaLabel='Welcome'>
        <Section className='c-portfolio-section' title='Top 5 Total Portfolio Holdings' subtitle={subtitle}>
          <GeneralError title='Failed to load Portfolio Top 5 Total Portfolio Holdings' onClick={refetch} />
        </Section>
      </Card>
    )
  }

  const handleExcelDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Top 5 Total Portfolio Holdings',
      fileName: `top-5-total-portfolio-holdings-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
    })
  }

  return (
    <Card className='c-onboarding-welcome__right__card card-right c-top-five-card' ariaLabel='Welcome'>
      {
        isFetching ? <SkeletonClientPortfolio /> :
        <Section className='c-portfolio-section' title='Top 5 Total Portfolio Holdings' subtitle={subtitle} actions={[ !isMobile &&
          <div  key='downloadRecentTradesBtn' className='download-button-container'>
            <DefaultButton className='download-button'  onClick={handleExcelDownloadClick}>
              <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
            </DefaultButton>
          </div>,
        ]}>
          <TopFiveHoldingGrid portfolioSettings={portfolioSettings} rows={data?.result} sendGridApi={setGridApi} />
        </Section>
      }
    </Card>
  )
}
