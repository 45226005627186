import { CellMouseOutEvent, CellMouseOverEvent, GridApi, RowClickedEvent } from 'ag-grid-community'
import { CurrencyColumn, IDataGridCellStyleParams, PercentageColumn, TextColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { IAllocation, IPortfolioSettings, getGridExcelStyles, roundMarketValueWidgetValue, roundMarketValueWidgetValueInMillions } from '@/shared/api/services/portfolioService'
import useIsMobile from '@/shared/hooks/useIsMobile'

import './AllocationCharGrid.scss'

export interface AllocationChartGridProps {
  rows: IAllocation[],
  portfolioSettings: IPortfolioSettings,
  sendGridApi: (gridApi: GridApi) => void,
  onMouseLeave?: (event: CellMouseOutEvent) => void
  onRowClick?: (event: RowClickedEvent) => void
  onRowHover?: (event: any) => void
  onAllocationIndexUpdate?: (index: number) => void
  showClickRowTooltip?: (show: boolean) => void
}

const AllocationChartGrid = ({
  rows,
  portfolioSettings,
  sendGridApi,
  onAllocationIndexUpdate,
  onMouseLeave,
  onRowClick,
  onRowHover,
  showClickRowTooltip
}: AllocationChartGridProps) => {
  const isLargeMobile = useIsMobile('mm')
  const isMediumMobile = useIsMobile('md')
  const allocationSummarySettings = portfolioSettings?.allocationSummary
  const getInvestmentCellStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any
    styles['boxShadow'] = `5px 0px ${data.color} inset`
    styles['boxSizing'] = 'border-box !important'
    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }
    return styles
  }
  const getCurrencySymbol = (data) => {
    return data?.currencySymbol
  }

  const getRowStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any
    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }

    if (data?.name === 'Total Assets') {
      styles['font-weight'] = '600'
    }

    return styles
  }

  const getCellClass = (params: IDataGridCellStyleParams) => {
    if (params.rowIndex !== 0) {
      return 'c-allocation-tab'
    }
    return ''
  }

  const onGridInit = (event: DataGridInitEvent) => {
    const { gridApi } = event
    sendGridApi(gridApi)
  }

  const textColumnWidth = isMediumMobile ? 250 : (isLargeMobile ? 180 : 210)
  const getAggregate = (params) => {

    const row = params
    const { name, statusAggregate } = row || {};
    let disName = '';

    if (statusAggregate) {
      if (statusAggregate === 'Preliminary') {
        disName = " * "
      } else if (statusAggregate === 'PROXY') {
        disName = " + "
      }
    }
    return name + disName
  }

  const onHover = (event: CellMouseOverEvent) => {
    showClickRowTooltip(false)
    const sleeveIdIndex = rows.findIndex(element => event.data.sleeveId === element['sleeveId'])
    const isSleeveIdIndexValid = sleeveIdIndex !== -1

    if (isSleeveIdIndexValid) {
      onAllocationIndexUpdate(sleeveIdIndex - 1)
    }

    if (onRowHover) {
      onRowHover(event)
    }
  }

  const getToolTipText = (params) => {
    const data = params?.data || {}
    return `Click to see ${data.name}'s Data`
  }

  return (
    <DataGrid
      className='c-portfolio-allocation'
      showToolBar={false}
      rows={rows}
      enablePagination={false}
      domLayout='normal'
      onInit={onGridInit}
      excelStyles={getGridExcelStyles({
        currencySymbol: portfolioSettings?.currency?.symbol,
        dateFormat: portfolioSettings?.dateFormat,
      })}
      onRowClick={onRowClick}
      onRowMouseOut={onMouseLeave}
      onRowHover={onHover}
    >
      <TextColumn pinned='left' lockPinned={true} disableFilterColumn={true} title=' ' width={textColumnWidth} resizable={true} headerName='nameColumn' field='name' checkboxSelection={false} cellClass={getCellClass} cellStyle={getInvestmentCellStyle} valueGetter={getAggregate} tooltipValueGetter={getToolTipText} />
      <CurrencyColumn hide={!allocationSummarySettings?.marketValueInMillions?.display} headerClass='text-break-right' title='Current Market value (M)' disableFilterColumn={true} field='currentMktValue' cellStyle={getRowStyle} getCurrencySymbol={getCurrencySymbol} width={isLargeMobile ? 135 : 115}
        decimalScale={1} valueGetter={value => {
          return roundMarketValueWidgetValueInMillions(value?.currentMktValue)
        }} />
      <CurrencyColumn hide={!allocationSummarySettings?.marketValue?.display} headerClass='text-break-right' title='Current Market value' disableFilterColumn={true} field='currentMktValue' cellStyle={getRowStyle} getCurrencySymbol={getCurrencySymbol} width={isLargeMobile ? 135 : 125}
        decimalScale={0} valueGetter={value => {
          return roundMarketValueWidgetValue(value?.currentMktValue)
        }} />
      <PercentageColumn hide={!allocationSummarySettings?.allocation?.display} headerClass='four-text-break-right' title='Actual Allocation (%)' disableFilterColumn={true} field='actualAllocation' cellStyle={getRowStyle} width={isLargeMobile ? 110 : 115} />
      <PercentageColumn hide={!allocationSummarySettings?.target?.display} headerClass='text-break-right' title='Long term target (%)' disableFilterColumn={true} field='longTrmTrgt' cellStyle={getRowStyle} width={100} />
      <PercentageColumn hide={!allocationSummarySettings?.longTermTargetVariance?.display} headerClass='six-text-break-right' title='Long term target variance (%)' disableFilterColumn={true} field='longTrmTrgtVariance' cellStyle={getRowStyle} width={140} />
      <PercentageColumn hide={!allocationSummarySettings?.targetRange?.display} headerClass='text-break-right' title='Target Range (%)' disableFilterColumn={true} field='TgtRng' cellStyle={getRowStyle} width={140} />
      <PercentageColumn hide={!allocationSummarySettings?.interimTargetVariance?.display} headerClass='six-text-break-right' title='Interim Targe Variance (%)' disableFilterColumn={true} field='longTrmTrgtVariance' cellStyle={getRowStyle} width={140} />
    </DataGrid>
  )
}

export default AllocationChartGrid
