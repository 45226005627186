import { ColumnActionsMode } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import ContactCell from './components/ContactCell'
import ActionCell from './components/ActionCell'
import ImpersonateCell from './components/ImpersonateCell'
import { useSelector } from 'react-redux'
import { EUserCan, Permission } from '@/features/auth/types'
import { selectPermissions } from '@/features/auth/redux/authSelectors'
import { useGetContactsByAccountIdQuery } from '@/shared/api/services/crmService'
import { IClientContactViewModel } from '@/shared/types/swagger'
import LocalPaginatedTable from '@/shared/components/Table/components/LocalPaginatedTable'
import getName from '../../helpers/getName'
import { IModifiedColumn } from '@/shared/components/Table/types'
import TosCell from './components/TermsOfService/TosCell'

type ContactsTableProps = {
  accountid: string
}

export default function ContactsTable({ accountid }: ContactsTableProps) {
  const { t } = useTranslation('contacts')
  const { data, isLoading, isUninitialized } = useGetContactsByAccountIdQuery(accountid)
  const permissions: Array<Permission> = useSelector(selectPermissions)

  const getColumnsWidth = (rowData: any[]) => {
    const PIXEL_BY_CHAR = 5
    const PADDING = 40
    const columnsWidth = {}

    if (rowData && rowData.length) {
      const columnNames = Object.keys(rowData[0]);

      rowData.forEach((row, i) => {
        columnNames.forEach(columnName => {
          const columnWidth = columnsWidth[columnName] || 0
          const columnValue = `${rowData[i][columnName] || ''}`.trim()

          const actualColumnWidth = (columnValue.length * PIXEL_BY_CHAR) + PADDING;

          if (actualColumnWidth > columnWidth) {
            columnsWidth[columnName] = actualColumnWidth
          }
        })
      })
    }
    return columnsWidth as any
  }

  const columnsWidth = getColumnsWidth(data)

  // for e-mail column
  const emailWidth = Math.max((columnsWidth['email1'] || 0), (columnsWidth['email2'] || 0), 160)

  // admin columns disable checks
  const isTermsOfServiceColumnDisabled = !permissions.includes(EUserCan.View_Contact_TermsOfService_History)
  const isActionsColumnDisabled = !permissions.includes(EUserCan.Send_Invites)
  const isImpersonateColumnDisabled = !permissions.includes(EUserCan.Impersonate_Users)
  
  // contacts table source columns
  const sourceColumns: Array<IModifiedColumn> = [
    {
      key: 'preferredName',
      getValue: getName,
      name: t('contact').toUpperCase(),
      fieldName: 'preferredName',
      minWidth: 180,
      onRender: ContactCell,
      flexGrow: 0,
      className: 'contact-name',
      headerClassName: 'contact-name'
    },
    {
      key: 'iLevel',
      name: t('iLevel'),
      fieldName: 'iLevel',
      onRender: (contact) => contact.iLevel ? 'Yes' : 'No',
      minWidth: 40,
      flexGrow: 0
    },
    {
      key: 'publicOpticaResearch',
      name: t('public ors').toUpperCase(),
      fieldName: 'publicOpticaResearch',
      onRender: (contact) => contact.publicOpticaResearch !== '' ? contact.publicOpticaResearch : 'No',
      minWidth: 70,
      flexGrow: 0
    },
    {
      key: 'privateOpticaResearch',
      name: t('private ors').toUpperCase(),
      fieldName: 'privateOpticaResearch',
      onRender: (contact) => contact.privateOpticaResearch !== '' ? contact.privateOpticaResearch : 'No',
      minWidth: 80,
      flexGrow: 0
    },
    {
      key: 'opticaBenchmark',
      name: t('optica benchmarks').toUpperCase(),
      fieldName: 'opticaBenchmark',
      onRender: (contact) => contact.opticaBenchmark ? 'Yes' : 'No',
      minWidth: 90,
      flexGrow: 0
    },
    {
      key: 'opticaPeers',
      name: t('optica peers').toUpperCase(),
      fieldName: 'opticaPeers',
      onRender: (contact) => contact.opticaPeers ? 'Yes' : 'No',
      minWidth: 50,
      flexGrow: 0
    },
    {
      key: 'jobTitle',
      name: t('title').toUpperCase(),
      fieldName: 'jobTitle',
      getValue: (contact: IClientContactViewModel) => contact.jobTitle ?? '',
      minWidth: Math.max((columnsWidth['jobTitle'] || 0), 50),
      flexGrow: 0,
    },
    {
      key: 'email1',
      name: t('email').toUpperCase(),
      fieldName: 'email1',
      getValue: (contact: IClientContactViewModel) => contact.email1 ?? contact.email2 ?? '',
      minWidth: emailWidth,
      flexGrow: 20
    },
    {
      key: 'tosAcceptedDate',
      name: t('terms of service date').toUpperCase(),
      fieldName: 'tosAcceptedDate',
      onRender: (contact: IClientContactViewModel) =>  <TosCell contact={contact} accountId={accountid} /> ,
      minWidth: 100,
      flexGrow: 20,
      disabled: isTermsOfServiceColumnDisabled,
      className: 'terms-of-service-column',
      headerClassName: 'terms-of-service-column',
      getValue: (contact: IClientContactViewModel) => {
        if (contact) {
          if (contact.termsOfServiceHistory) {
            const { termsOfServiceAcceptedDate, termsOfServiceLastResetDate, termsOfServiceDateHistory } = contact.termsOfServiceHistory

            if (termsOfServiceAcceptedDate) {
              return new Date(contact.termsOfServiceHistory.termsOfServiceAcceptedDate)
            }

            if (termsOfServiceLastResetDate) {
              return 3
            }

            if (termsOfServiceDateHistory && termsOfServiceDateHistory.length) {
              return 2
            }
          }
        }

        return 1
      },
    },
    {
      key: 'actions',
      name: '',
      fieldName: 'actions',
      ariaLabel: t('contactActions'),
      minWidth: 50,
      onRender: (contact: IClientContactViewModel) => <ActionCell key={contact.contactId} contact={contact} />,
      disabled: isActionsColumnDisabled,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      flexGrow: 0,
      styles: {
        root: {
          '.ms-DetailsRow-cell': {
            padding: 0,
            textAlign: 'cemter'
          }
        }
      },
      className: 'actions-column',
      headerClassName: 'actions-column'
    },
    {
      key: 'impersonate',
      name: '',
      fieldName: 'impersonate',
      minWidth: 70,
      onRender: (contact) => <ImpersonateCell contact={contact} />,
      columnActionsMode: ColumnActionsMode.disabled,
      flexGrow: 0,
      disabled: isImpersonateColumnDisabled,
      className: 'impersonate-column',
      headerClassName: 'impersonate-column'
    },
  ]
  const columns = sourceColumns.filter((col) => !col.disabled)

  return (
    <LocalPaginatedTable
      className={`contacts-table ${!isTermsOfServiceColumnDisabled ? 'has-terms-of-service-column' : ''} ${!isActionsColumnDisabled ? 'has-actions-column' : ''} ${!isImpersonateColumnDisabled ? 'has-impersonate-column' : ''}`}
      data={data || []}
      columns={columns}
      shimmerIsEnabled={isLoading || isUninitialized}
      useCard
      cardProps={{
        ariaLabel: t('tableTitle')
      }}
    />
  )
}
