import { GroupBarChart } from '@/lib/common/components/charts/group-bar-chart/GroupBarChart'
import { Card, GeneralError, Section } from '@/shared/components'
import { BENCHMARK_DISPLAY_TYPE, IPortfolioPerformanceSummaryFieldSetting, NET_OF_FEE_DISPLAY_TYPE, POLICY_BENCHMARK_NODE_TYPE, PerformanceRawData, SLEEVE_DISPLAY_TYPE, VALUE_ADDED_DISPLAY_TYPE, PERFORMANCE_MEDIAN_KEYS, useGetPortfolioSettingsByIdQuery, useGetStatsPerformanceQuery, exportGridData, getPerformanceRawValue } from '@/shared/api/services/portfolioService'
import SkeletonClientPortfolioPerformance from '../Skeletons/SkeletonClientPortfolioPerformance'
import PerfromanceChartGrid from './PerformanceGridChart/grid/PerformanceChartGrid'
import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import useIsMobile from '@/shared/hooks/useIsMobile'
import PerformanceChartLegend from './PerformanceGridChart/legend/PerformanceChartLegend'
import { useEffect, useState } from 'react'
import { ColDef, GridApi, ProcessCellForExportParams } from 'ag-grid-community'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CURRENCY_COLUMN_CELL_CLASS, DATE_COLUMN_CELL_CLASS, DATE_COLUMN_FORMAT, DATE_TIME_COLUMN_CELL_CLASS, DATE_TIME_COLUMN_FORMAT, PERCENTAGE_COLUMN_CELL_CLASS } from '@/lib/common/components/grid/GridExport'
import ReturnToTotalPortfolioButton from '../TotalPortfolioButton/ReturnToTotalPortfolio'

import './Performance.scss'


export interface PerformanceProps {
  portfolioId: string, 
  asOfDate: string,
  sleeveId?: string
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}
export const EXPORT_SLEEVE_NAME_CELL_CLASS = "PerformanceChartGridSleeveNameColumn"

export default function Performance({ 
  portfolioId, 
  asOfDate,
  sleeveId
}: PerformanceProps) {
  const isMobile = useIsMobile('xs')
  const parsedSleeveId = !!sleeveId ? Number(sleeveId) : 0
  const { data, isFetching, isError, refetch } = useGetStatsPerformanceQuery({ portfolioId, asOfDate })
  const { data: portfolioSettings } = useGetPortfolioSettingsByIdQuery(portfolioId)
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, portfolioSettings?.headerDateFormat || 'MMM dd, yyyy')}`
  const performanceSummarySettings = portfolioSettings?.performanceSummary
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [ gridApi, setGridApi ] = useState(null as GridApi)
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (isError) {
    return (
      <Card className='card-center c-card-performance-chart c-card-performance c-card-error' ariaLabel='Welcome'>
        <Section className='c-portfolio-section' title='Performance (%)' subtitle={subtitle}>
          <GeneralError title='Failed to load Portfolio Performance' onClick={refetch} />
        </Section>
      </Card>
    )
  }

  const result = (data || [])
  const totalAssets = result.find(p => p.DisplayPLIType === 'Sleeve' && p.Lvl === 0)     
  
  // parent sleeve node
  const sleeve = result.find(p => {
    if (parsedSleeveId) {
      return (p.PLISk === parsedSleeveId) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
    }
      return (p.Lvl === 0) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
  })

  // policy benchmark node
  let policyBenchmark = result.find(p => {
    return (p.GroupPLISk === sleeve?.GroupPLISk) && (p.NodeType === POLICY_BENCHMARK_NODE_TYPE)
  })

  if (!policyBenchmark) {
    // sets first benchmark node if no policy benchmnark node found
    policyBenchmark = result.find(p => {
      return (p.GroupPLISk === sleeve?.GroupPLISk) && (p.DisplayPLIType === BENCHMARK_DISPLAY_TYPE)
    })
  }

  // value add node
  const valueAdd = result.find(p => {
    return (p.GroupPLISk === sleeve?.GroupPLISk) && (p.DisplayPLIType === VALUE_ADDED_DISPLAY_TYPE)
  })

  // net of fee node
  let netOfFee

  if (performanceSummarySettings?.displayNetOfFees) {
    // sets first with NetOfCAFees display type
    netOfFee = result.find(p => {
      return (p.GroupPLISk === sleeve?.GroupPLISk) && (p.DisplayPLIType === NET_OF_FEE_DISPLAY_TYPE)
    })
  }

  const periods = Object.keys(performanceSummarySettings || {}).reduce((acc, settingKey) => {
    const setting = performanceSummarySettings[settingKey]
    if ((typeof setting === 'object') && setting) {
      const config = setting as IPortfolioPerformanceSummaryFieldSetting
      if (config.display) {
        acc.push((isMobile || windowDimensions.width < 1810 ?  config.mobileColumnName : config.desktopColumnName))
      }
    }
    return acc
  }, [])

  // filtered performance options
  let options = [
    sleeve,
    policyBenchmark,
    valueAdd,
    netOfFee,
  ].filter(option => !!option)

  if (performanceSummarySettings) {
    const { Lvl: sleeveLevel } = sleeve || {} as PerformanceRawData

    // checks for non-root sleeve
    if (sleeveLevel > 0) {
      // removes data that is not allowed from Vermilion settings
      const removeData = ((sleeveLevel == 1) && (!performanceSummarySettings.displayPerformanceSumaryLevel1))
        || ((sleeveLevel === 2) && (!performanceSummarySettings.displayPerformanceSumaryLevel2))
        || ((sleeveLevel === 3) && (!performanceSummarySettings.displayPerformanceSumaryLevel3))
        || ((sleeveLevel === 4) && (!performanceSummarySettings.displayPerformanceSumaryLevel4))
        || ((sleeveLevel === 5) && (!performanceSummarySettings.displayPerformanceSumaryLevel5))
        || (sleeveLevel > 5)

      if (removeData) {
        options = []
      }
    }
  }

  const seriesChart = options.filter(op => op !== netOfFee).map((op, index) => {
    let color = op.Color
    if (op === policyBenchmark) {
      color = '#cccccc'
    } else if (op === valueAdd) {
      color = '#0c3980'
    }
    return {
      name: op.Name,
      values: performanceSummarySettings && Object.keys(performanceSummarySettings).reduce((acc, settingKey) => {
        const setting = performanceSummarySettings[settingKey]
        if ((typeof setting === 'object') && setting) {
          const config = setting as IPortfolioPerformanceSummaryFieldSetting
          if (config.display) {
            acc.push(op[config.objectProperty])
          }
        }
        return acc
      }, []),
      color,
      ...op
    } 
  })

  const legendOptions = options.filter(op => op !== netOfFee).map(op => {
    if (op === policyBenchmark) {
      return {
        ...op,
        Color: '#cccccc',
      }
    } else if (op === valueAdd) {
      return {
        ...op,
        Color: '#0c3980',
      }
    }
    return { ...op }
  })
  
  const handleExcelDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Performance',
      fileName: `performance-summary-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
      processCellCallback: (cellCallbackParams: ProcessCellForExportParams) => {
        const { value, column, node } = cellCallbackParams
        const { colDef: columnDef } = column as any
        const colDef = columnDef as ColDef
        const { cellClass } = colDef
        const { originalCellClass } = colDef as any
        const data = node && node.data
        const field = colDef && colDef.field
        const className = (originalCellClass && (typeof originalCellClass === 'string')) ? originalCellClass : cellClass
    
        if (value && cellClass) {
          const hasCellClass = (cellClassName: any, expectedCellClass: string) => {
            if (Array.isArray(cellClassName)) {
              return cellClassName.includes(expectedCellClass)
            }
            return `${cellClassName || ''}`.includes(expectedCellClass)
          }
    
          if (hasCellClass(className, DATE_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_COLUMN_FORMAT)
          } else if (hasCellClass(className, DATE_TIME_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_TIME_COLUMN_FORMAT)
          } else if (hasCellClass(className, CURRENCY_COLUMN_CELL_CLASS)) {
            if (data && field) {
              try {
                const currencyValue = Number(data[field] || value)?.toFixed(4)
                return currencyValue
              } catch (error) {
                console.error('Error while parsing currency column value.', error)
              }
            }
          } else if (hasCellClass(className, PERCENTAGE_COLUMN_CELL_CLASS)) {
            try {
              const percentageValue = getPerformanceRawValue(data, field)
              return percentageValue?.toFixed(6)
            } catch (error) {
              console.error('Error while parsing percentage column value.', error)
            }
          }
        }
    
        return value
      }
    })
  }

  const getFormattedValue = (value, data) => {
    if (value && (PERFORMANCE_MEDIAN_KEYS && (data.DisplayPLIType).toLowerCase().indexOf('median') === -1)) {
      if (value < 0) {
        return ((parseFloat(value)*100).toFixed(1))
      }
      return (parseFloat(value)*100).toFixed(1)
    }

    if (value && PERFORMANCE_MEDIAN_KEYS && (data.DisplayPLIType).toLowerCase().indexOf('median') >= 0 && PERFORMANCE_MEDIAN_KEYS[1] !== data.Name && PERFORMANCE_MEDIAN_KEYS[0] !== data.Name) {
      if(value < 0){
        return (parseFloat(value)).toFixed(1) 
      }

      return parseFloat(value).toFixed(1)
    }
    if (value && PERFORMANCE_MEDIAN_KEYS && (data.DisplayPLIType).toLowerCase().indexOf('median') >= 0 && (PERFORMANCE_MEDIAN_KEYS[1] === data.Name || PERFORMANCE_MEDIAN_KEYS[0] === data.Name)) {
      return (parseFloat(value)).toFixed(0)
    }
    if (value === 0 && PERFORMANCE_MEDIAN_KEYS && (data.DisplayPLIType).toLowerCase().indexOf('median') >= 0 && (PERFORMANCE_MEDIAN_KEYS[1] === data.Name || PERFORMANCE_MEDIAN_KEYS[0] === data.Name)) {
      return value
    }
    return value
  }


  const getSumPeriodColumns = () => {
    const performancePeriodData = portfolioSettings?.performanceSummary
    let count = 0
    for (const key in performancePeriodData) {
      if (key.startsWith('performanceSumary') && performancePeriodData[key]?.display) {
        count++
      }
    }
    return count
  }

  const showTotalAssetsButton = () => {
    const { id } = totalAssets || {} as any
    if (sleeveId && totalAssets?.id?.toString() !== sleeveId) {
      return (
        <div key={0}>
          <ReturnToTotalPortfolioButton portfolioId={portfolioId} asOfDate={asOfDate} id={id} />
        </div>
      )
    }
    return null
  }

  return (
    <Card className='card-center-performance PerformanceWidget' ariaLabel='Welcome'>
      <Section className='c-portfolio-section' title='Performance (%)' subtitle={subtitle} actions={[
        <div className='button-container' key={0}>
          {showTotalAssetsButton()}
          {!isMobile && (<div key='downloadBtn' className='download-button-container'>
            <DefaultButton className='download-button' onClick={handleExcelDownloadClick}>
              <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
            </DefaultButton>
          </div>)}
        </div>,]}>
        {
          isFetching ? < SkeletonClientPortfolioPerformance /> : (
            <div className={`c-section-line ${getSumPeriodColumns() > 5 ? 'multicolumns' : ''}`}>
              <PerfromanceChartGrid portfolioSettings={portfolioSettings} rows={options} sendGridApi={setGridApi}/>
              <GroupBarChart
                multipleColumn={getSumPeriodColumns() > 5}
                legend={{
                  position: 'right',
                  style: 'italic'
                }}
                fontOptions={{
                  font: {
                    size: 16,
                    style: 'italic'
                  }
                }}
                valueFormatter={{ getFormattedValue }}
                periods={periods}
                series={seriesChart}
                xAxis={{ labelPadding: isMobile ? 0 : 30 }}
                onSeriesClick={(seriesItem) => seriesItem}
                onSeriesHover={(seriesItem) => seriesItem}
              />
              <PerformanceChartLegend multipleColumns={getSumPeriodColumns() > 5} options={legendOptions}/>
            </div> )
        }
      </Section>
    </Card>
  )
}
