import React, { useRef } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import NumberFormat from 'react-number-format'
import UtilService from '../../../services/util/UtilService'


interface ICellRendererMedParams extends ICellRendererParams {
  medians: any
}

export const PercentageCellRenderer = (props: ICellRendererMedParams) => {
  const { colDef } = props
  const componentId = UtilService.getId(
    `${colDef.colId}_currency_cell_renderer`
  )
  const elementReference = useRef(null)
  const setTitle = (title: string) => {
    const { current } = elementReference
    if (current) {
      const element = elementReference.current as any as HTMLElement
      element.title = title
    }
  }
  let elemClass = 'text-right'
  if(props.value < 0) {
    elemClass = 'text-danger ' + elemClass
  }

  return (
    <div
      ref={elementReference}
      className={elemClass}
      style={{
        textAlign: 'right',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      id={componentId}
    >
      <NumberFormat
        value={props.value}
        displayType='text'
        thousandSeparator={true}
        decimalSeparator='.'
        decimalScale={1}
        fixedDecimalScale={true}
        renderText={(value) => {
          let formattedValue
            if(props.value < 0) {
              formattedValue = '(' + value.replace('-', '')  + ')'
            }
            else {
              formattedValue = (value ? value  : '---')
            }
          setTitle(formattedValue)
          return formattedValue
        }}
      />
    </div>
  )
}
