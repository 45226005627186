import { PerformanceRawData } from '@/shared/api/services/portfolioService'
import { ICellRendererParams } from 'ag-grid-community'
import styles from './PerformanceGridAutoGroupCellRenderer.module.scss'

export const EXPORT_CELL_CLASS = 'PerformanceGridAutoGroupCellRenderer'

export const PerformanceGridAutoGroupCellRenderer = (props: ICellRendererParams) => {
    const row = props.data as PerformanceRawData
    const { Name: name, DisplayPLIType, PLIType, StatusAggregate } = row || {};
    const displayType = PLIType || DisplayPLIType
    let title;
    let disName='';

    if (displayType === 'Sleeve') {
      title = displayType + '(L' + props.data.Lvl + ')'
    }
    else {
      title = displayType
    }
    if(StatusAggregate) {
      if (StatusAggregate === 'Preliminary') {
        disName = " * "
      } else if(StatusAggregate === 'PROXY') {
        disName = " + "
      }
    }
    return (
      <div className={styles.container}>
        <div className={`${styles.text} ${styles[`${displayType}Text`]}`} title={name}>{name + disName}</div>
      </div>
    )
}
