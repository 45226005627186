import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { BENCHMARK_DISPLAY_TYPE, INVESTMENT_DISPLAY_TYPE, SLEEVE_DISPLAY_TYPE, VALUE_ADDED_DISPLAY_TYPE, exportGridData, getPerformanceRawValue, useGetPortfolioSettingsByIdQuery, useGetStatsPerformanceQuery } from '@/shared/api/services/portfolioService'
import { Card, GeneralError, Section } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { useState } from 'react'
import ClientPortfolioPerformanceMobile from './PerformanceMobile/ClientPortfolioPerformanceMobile'
import SkeletonClientPortfolioPerformance from './SkeletonClientPortfolioPerformance'
import ExpandCollapseSleeveFilter from './filters/ExpandCollapseSleeveFilter/ExpandCollapseSleeveFilter'
import PerformanceGrid, { PerformanceGridInitializeEvent } from './grid/PerformanceGrid'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColDef, GridApi, ProcessCellForExportParams } from 'ag-grid-community'
import Disclaimer from './Disclaimer'
import { CURRENCY_COLUMN_CELL_CLASS, DATE_COLUMN_CELL_CLASS, DATE_COLUMN_FORMAT, DATE_TIME_COLUMN_CELL_CLASS, DATE_TIME_COLUMN_FORMAT, PERCENTAGE_COLUMN_CELL_CLASS } from '@/lib/common/components/grid/GridExport'
import { EXPORT_CELL_CLASS } from './grid/cellFramework/PerformanceGridAutoGroupCellRenderer'

export interface ClientPortfolioPerformanceProps {
  portfolioId: string, 
  asOfDate: string,
  sleeveId?: string
}

export default function ClientPortfolioPerformance({ 
  portfolioId, 
  asOfDate,
  sleeveId,
}: ClientPortfolioPerformanceProps) {
  const [ isSleevesExpanded, setSleevesExpanded ] = useState<boolean>(false)
  const [ gridApi, setGridApi ] = useState(null as GridApi)
  const { data, isFetching, isError, refetch } = useGetStatsPerformanceQuery({ portfolioId, asOfDate })
  const { data: portfolioSettings } = useGetPortfolioSettingsByIdQuery(portfolioId)
  const performanceTabSettings = portfolioSettings?.performanceTab
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, portfolioSettings?.headerDateFormat || 'MMM dd, yyyy')}`
  const isMobile = useIsMobile('xs')

  if (isError) {
    return (
      <Card className='card-center card-ag-grid-wrapper card-ag-grid-performance' ariaLabel='Welcome'>
        <Section className='c-portfolio-section-chart' title='Performance' subtitle={subtitle}>
          <GeneralError title='Failed to load Portfolio Performance' onClick={refetch} />
        </Section>
      </Card>
    )
  }

  const handleGridInitialize = (event: PerformanceGridInitializeEvent) => {
    const { gridApi } = event
    setGridApi(gridApi)
  }

  const handleDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Performance',
      fileName: `performance-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
      processCellCallback: (cellCallbackParams: ProcessCellForExportParams) => {
        const { value, column, node } = cellCallbackParams
        const { colDef: columnDef } = column as any
        const colDef = columnDef as ColDef
        const { cellClass } = colDef
        const { originalCellClass } = colDef as any
        const data = node && node.data
        const field = colDef && colDef.field
        const className = (originalCellClass && (typeof originalCellClass === 'string')) ? originalCellClass : cellClass
    
        if (value && className) {
          const hasCellClass = (cellClassName: any, expectedCellClass: string) => {
            if (Array.isArray(cellClassName)) {
              return cellClassName.includes(expectedCellClass)
            }
            return `${cellClassName || ''}`.includes(expectedCellClass)
          }
    
          if (hasCellClass(className, DATE_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_COLUMN_FORMAT);
          } else if (hasCellClass(className, DATE_TIME_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_TIME_COLUMN_FORMAT);
          } else if (hasCellClass(className, CURRENCY_COLUMN_CELL_CLASS)) {
            if (data && field) {
              try {
                const currencyValue = Number(data[field] || value)?.toFixed(4)
                return currencyValue
              } catch (error) {
                console.error('Error while parsing currency column value.', error)
              }
            }
          } else if (hasCellClass(className, PERCENTAGE_COLUMN_CELL_CLASS)) {
            try {
              const percentageValue = getPerformanceRawValue(data, field)
              return percentageValue?.toFixed(6)
            } catch (error) {
              console.error('Error while parsing percentage column value.', error)
            }
          }
          else if (hasCellClass(className, EXPORT_CELL_CLASS)) {
            const { StatusAggregate } = data || {}
            let disName = ''
            
            if (StatusAggregate) {
              if (StatusAggregate === 'Preliminary') {
                disName = " * "
              } else if(StatusAggregate === 'PROXY') {
                disName = " + "
              }
              return `${value}${disName}`
            }
          }
        }
    
        return value
      }
    })
  }

  const filteredRowsFromSettings = (data || []).filter(p => {
    // removes benchmark nodes as per vermilion settings
    if ((p.DisplayPLIType === BENCHMARK_DISPLAY_TYPE) && !performanceTabSettings?.displayPerformanceBenchmarks) {
      return false
    }

    // removes value add nodes as per vermilion settings
    if ((p.DisplayPLIType === VALUE_ADDED_DISPLAY_TYPE) && !performanceTabSettings?.displayPerformanceValueAdd) {
      return false
    }

    // removes value add nodes as per vermilion settings
    if ((p.DisplayPLIType === INVESTMENT_DISPLAY_TYPE) && !performanceTabSettings?.displayPerformanceInvestments) {
      return false
    }

    // removes value add nodes as per vermilion settings
    if ((p.DisplayPLIType === SLEEVE_DISPLAY_TYPE) && !performanceTabSettings?.displayPerformanceSleeves) {
      return false
    }

    return true
  }).map(p => ({ ...p }))
  
  const filteredRows = filteredRowsFromSettings

  if (isMobile) {
    return (
      <>
        <ClientPortfolioPerformanceMobile asOfDate={asOfDate}  portfolioId={portfolioId}/>
        <Disclaimer/>
      </>
    )
  }

  return (
    <>
      <Card className='card-center card-ag-grid-wrapper card-ag-grid-performance' ariaLabel='Welcome'>
        {
          isFetching ? <SkeletonClientPortfolioPerformance /> :
          <Section className='c-portfolio-section-chart' title='Performance' subtitle={subtitle} actions={[
            <ExpandCollapseSleeveFilter key='ExpandCollapseSleeveFilter' isExpanded={isSleevesExpanded} onFilter={(filter) => {
              setSleevesExpanded(filter)
            }} />,
            <div  key='downloadBtn' className='download-button-container'>
              <DefaultButton className='download-button'  onClick={handleDownloadClick}>
                <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
              </DefaultButton>
            </div>
          ]}>
            { !isFetching ? <PerformanceGrid portfolioSettings={portfolioSettings} rows={filteredRows} isSleevesExpanded={isSleevesExpanded} onGridInitialize={handleGridInitialize} /> : null }
          </Section>
        }
        <Disclaimer/>
      </Card>
    </>
  )
}
